.footer{
    width: 100vw;
    height: 4rem;
    background: rgb(20, 17, 27);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 300;
    font-size: small;
}
