#works {
    min-height: calc(100vh - 4rem);
    width: 100vw;
    max-width:60rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 1rem;
    padding: 0 1rem;
margin: 0 auto;
}

.worktitle{
    margin: 1rem 0;
    font-size: 3rem;
}

.workdesk{
    font-weight: 300;
    font-size: 1rem;
}

.worksimgs{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100vw;
    max-width: 65rem;
}

.worksimg{
    object-fit: cover;
    height: 20rem;
    margin: 0.5rem;
}


