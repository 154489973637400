#intro {
    height: calc(100vh - 4rem);
    width: 100vw;
    max-width: 75rem;
    margin: 0 auto;
    overflow: hidden;
    position: relative;

}

.bg{
    position: absolute;
    top: 2rem;
    right: 2rem;
    z-index: -1;
    object-fit: cover;
    height: 100vh;

}

.introcontent{
    height: 100vh;
    width: 100vw;
    font-size: 3rem;
        padding: 2rem;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.hello{
    margin-bottom: 10px;
    font-size: 1.7rem;
    font-weight: 100;
    
  
}


.introName{
    color: red;
    font-size: 25px;
    margin-bottom: 3px;
}

.introtext{
    
    font-size: 25px;
    margin-bottom: 3px;
}
.intropara{
    font-weight: 300;
    font-size: large;
    letter-spacing: 1px;

}

.btn{
    background: red;
    margin: 1rem 0;
    padding: 0.75rem 2rem;
    border: none;
    border-radius: 2rem;
}

.btnImg{
    object-fit: cover;
    margin: 0 0.25rem;
    height: 2.5rem;
}


@media screen and (max-width:840) {
    .bg{
        right: -10vw;
    }
    .introcontent{
        font-size: 10vw;
    }
    .hello{
        font-size: 4.5vw;
    }
    
}

  