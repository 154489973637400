.navbar {
    background: rgb(20, 17, 27);
    height: 5rem;
    width: 100vw;
    max-width: 75rem  ;
    margin: 0 auto;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 3;
    border-radius: 1rem;
    

}
.logo{
    object-fit: cover;
    height: 3rem;
width: 4rem;
}

/* gap of navbar list item*/
.desktopMenuListItem{
    margin: 1rem;
    cursor:pointer ;
}

.desktopMenuListItem:hover {
    color: red;
    padding-bottom: 0.5rem;
    border-bottom: 3px solid purple;
}

.desktopmenuBtn{

    background:  purple;
    color: black;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
 
    border-radius: 2.5rem;
}

.desktopMenuImg{

    object-fit: cover;
    height: 2rem;
    width: 3.5rem;

}

.active{
    color: red;
   
    border-bottom: 3px solid purple;
}

.mobMenu{
    object-fit: cover;
    height: 1.8rem;
    display: none;
}

.navMenu{
    background: rgb(30, 30, 30);
    border-radius: 1rem;
    top: 4rem;
    right: 2rem;
    z-index: 2;
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    height: fit-content;
    min-height: 15rem;
  
    position: absolute;
}

.listItem{
    color: white;
    padding: 0.5rem 3rem;
    margin: 0.25rem;
    background: rgb(30, 30, 30);
}

 
@media screen and (max-width:720){
    .mobMenu{
        display: flex;

    }
    .desktopMenu{
        display: none;
    }
.desktopMenuBtn{
    display: none;
}

}