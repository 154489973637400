.adress, .email, .phone{
    font-size: medium;
    width: 100%;
    
   
    margin: 1rem;
    padding: 0.5rem 1rem;
    color: white;
    border: none;
    border-radius: 0.5rem;
    margin-left: -2.5rem;
   
}
#contactpage{
    align-items: center;
    justify-content: center;
    text-align: center;
   

}
.contactpagetitle{
    color: yellow;
}