#skills {
    overflow: hidden;
    width: 100vw;
    max-width: 65rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
}

.skillTitle{
    font-size: 3rem;
    font-weight: 600;
    padding-top: 3rem;
    margin-bottom: 1.5rem;
    color: yellow;
}

.skillDesc{
    font-weight: 400;
    font-size: 1rem;
    max-width: 51rem;
    padding: 0 2rem;
}

.skillBars{
    margin: 1.5rem;
    width: 100vw;
    max-width: 80%;
    text-align: left;

}

.skillBar{
    display: flex;
    margin: 1rem;
    padding: 1.5rem 2rem;
    border-radius: 0.5rem;
    background: rgb(50, 50, 50);
}

.skillBarImg{
    object-fit: cover;
    height: 3rem;
    width: 3rem;
    margin-right: 2rem;

}

.skillBarText{
    font-size: 0.9rem;
    font-weight: 200;
    
}


@media screen  and (max-width:720px){
    .skillBarText>p{
        font-size: 2vw;
        
    }
    
}
@media screen and ( max-width:720px){
 
    .skillBarText>p{
        font-size: 3vw;
       
    }
    .skillBarImg{
        height: 2.25rem;
        width: 2.25rem;
        max-width:720px;

    }
    .skillBarText>h2{
        font-size: 5vw;
        max-width:720px;
    }
    
}